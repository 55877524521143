$('.menufilter').change(function (event) {
    var level = $(this).attr('data-level');
    if ($(this).val() != "all") {
        $('.waitscreen').addClass('in');
        data = {action: 'get_subcats', term_id: $(this).val(), level: level};
        $.post(MyAjax.ajaxurl, data, function (response) {
            if (response.indexOf('<option') === -1) {
                // filtercat();
            } else if (level == "lvl1") {
                $('#fahrzeugmodell').removeAttr('disabled').html(response);
                $('#baureihe').attr('disabled', 'disabled').html('<option value="all">Baureihe</option>');
                $('#baujahr').attr('disabled', 'disabled').html('<option value="all">Modell</option>');
            } else if (level == "lvl2") {
                $('#baureihe').removeAttr('disabled').html(response);
                $('#baujahr').attr('disabled', 'disabled').html('<option value="all">Modell</option>');
                if($('#baureihe option').length == 2 ) {
                    $('#baureihe').val($('#baureihe option:last').val()).change();
                }
                filtercat();
            } else if (level == "lvl3") {
                $('#baujahr').removeAttr('disabled').html(response);
                // if($('#baujahr option').length == 2 ) {
                //     $('#baujahr').val($('#baujahr option:last').val()).change();
                // }
            }

            $('.waitscreen').removeClass('in');
        });
    } else {
        if (level == "lvl2") {
            $('#baureihe').attr('disabled', 'disabled').html('<option value="all">Baureihe</option>');
            $('#baujahr').attr('disabled', 'disabled').html('<option value="all">Modell</option>');
        } else if (level == "lvl3") {
            $('#baujahr').attr('disabled', 'disabled').html('<option value="all">Modell</option>');
        }
        filtercat();
    }
});
function filtercat() {
    var link = "";
    if ($('#baujahr').val() != "all") {
        link = $('#baujahr option:selected').attr('data-url');
    } else if ($('#baureihe').val() != "all") {
        link = $('#baureihe option:selected').attr('data-url');
    } else if ($('#fahrzeugmodell').val() != "all") {
        link = $('#fahrzeugmodell option:selected').attr('data-url');
    } else if ($('#fahrzeugmarke').val() != "all") {
        link = $('#fahrzeugmarke option:selected').attr('data-url');
    }

    var sort = $('#sortierung').val();
    var issort = false;
    if (sort != 'default') {
        link += '?sortby=' + sort;
        issort = true;
    }

    var zoll = '';
    $('#zoll input:checked').each(function (index, el) {
        if (index > 0) {
            zoll += ',';
        }
        zoll += $(this).val();
    });
    if (issort) {
        if (zoll != '') {
            link += '&zoll=' + zoll;
        }
        if(link!=''){
            window.location.href = link;
        }else{
            window.location.href = window.location.origin + link;
        }
    } else {
        if (zoll != '') {
            link += '?zoll=' + zoll;
        }
        if(link!=''){
            window.location.href = link;
        }else{
            window.location.href = window.location.origin + link;
        }
    }
}

$('#filtercat').click(function (event) {
    filtercat();
});
$('#sortierung').change(function (event) {
    filtercat();
});
$(document).ready(function () {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="popover"]').click(function (event) {
        event.preventDefault();
    });
    //set like binding
    $('.likebutton').click(function (event) {
        event.preventDefault();
        like(this);
        $(this).blur();
    });
    //set unlike binding
    $('.unlikebutton').click(function (event) {
        event.preventDefault();
        unlike(this);
        $(this).blur();
    });
});

function like(selector) {
    data = {action: 'add_like', post_id: $(selector).attr('data-post-id')};
    var count = parseInt($('.like-' + $(selector).attr('data-post-id')).html());
    $.post(MyAjax.ajaxurl, data, function (response) {
        //change Classes and Button Text
        $(selector).removeClass('likebutton');
        $(selector).addClass('unlikebutton');
        $(selector).find('i').removeClass('fa-heart');
        $(selector).find('i').addClass('fa-times');
        $(selector).find('span').html("Gefällt mir nicht mehr");
        $('.like-' + $(selector).attr('data-post-id')).html(count + 1);
        //reset binding
        $(selector).off('click').on('click', function (event) {
            event.preventDefault();
            unlike(this);
            $(this).blur();
        });
    });
}

function unlike(selector) {
    data = {action: 'remove_like', post_id: $(selector).attr('data-post-id')};
    var count = parseInt($('.like-' + $(selector).attr('data-post-id')).html());
    $.post(MyAjax.ajaxurl, data, function (response) {
        //change Classes and Button Text
        $(selector).removeClass('unlikebutton');
        $(selector).addClass('likebutton');
        $(selector).find('i').removeClass('fa-times');
        $(selector).find('i').addClass('fa-heart');
        $(selector).find('span').html("Gefällt mir");
        $('.like-' + $(selector).attr('data-post-id')).html(count - 1);
        console.log(count);
        //reset binding
        $(selector).off('click').on('click', function (event) {
            event.preventDefault();
            like(this);
            $(this).blur();
        });
    });
}
//upload Image function
$(document).ready(function () {
    $('body').on('change', '.files-data', function (e) {
        e.preventDefault;
        var fdata = new FormData();
        var files_data = $('.upload-form .files-data');
        $.each($(files_data), function (i, obj) {
            $.each(obj.files, function (j, file) {
                fdata.append('my_file_upload[' + j + ']', file);
            })
        });
        fdata.append('action', 'cvf_upload_files');
        fdata.append('post_id', $(this).attr('data-post-id'));
        $.ajax({
            url: MyAjax.ajaxurl,
            type: "POST",
            data: fdata,
            processData: false,
            contentType: false,
            success: function () {
                location.reload();
            }
        });
    });
    var batch = false;
    $(window).scroll(function () {
        if ($('.show-fixed-batch').length != 0) {
            if ($('.show-fixed-batch').offset().top < $(document).scrollTop() && !batch) {
                $('.fixed-batch').stop(true, true).animate({'right': 0});
                batch = true;
            }
            if ($('.show-fixed-batch').offset().top > $(document).scrollTop() && batch) {
                $('.fixed-batch').stop(true, true).animate({'right': '-140'});
                batch = false;
            }
        }
    });
});
$('#searchform').submit(function (event) {
    if ($(this).find('input[type="text"]').val() == "") {
        event.preventDefault();
    }
});
var options = [];

$('.dropdown-menu a').on('click', function (event) {

    var $target = $(event.currentTarget),
        val = $target.attr('data-value'),
        $inp = $target.find('input'),
        idx;

    if (( idx = options.indexOf(val) ) > -1) {
        options.splice(idx, 1);
        setTimeout(function () {
            $inp.prop('checked', false)
        }, 0);
    } else {
        options.push(val);
        setTimeout(function () {
            $inp.prop('checked', true)
        }, 0);
    }

    $(event.target).blur();
    console.log(options);
    return false;
});
$('#pdf-btn').click(function (e) {
    e.preventDefault();
    data = {action: 'pdf_create', post_id: $(this).attr('data-post-id')};
    $.post(MyAjax.ajaxurl, data, function (response) {
        console.log(response);
    });
});

$('.variant-select').change(function (event) {
    window.location.href =  $(this).val();
});
function search_submit() {
    $('#searchform').submit();
}

// **************** amazon - search ****************************************************************************/
function search_amazon_article() {
    var affiliateInfos   = $('#amazon-affiliate');

    if(affiliateInfos.length >= 0) {

        var dataSearchNumber = affiliateInfos.data('search-number'),
            dataSearchAll = affiliateInfos.data('search-all'),
            dataShopId = affiliateInfos.data('search-affiliate-id'),
            divResult = $('#amazon-affiliate-result'),
            strEmptyResult = 'noresultfound';

        get_search(dataShopId, dataSearchNumber)
            .then(function (data) {
                var html = ('html' in data[0]) ? data[0].html : strEmptyResult;
                if (html === strEmptyResult) {
                    console.log(dataShopId, dataSearchAll);
                    //return get_search(dataShopId, dataSearchAll);
                } else {
                    divResult.html(html);
                }
            })
            .then(function (data) {
                if (typeof data === "undefined") {

                } else {
                    var html = ("html" in data[0]) ? data[0].html : strEmptyResult;
                    if (html === strEmptyResult) {
                        divResult.html('Kein Ergebnis gefunden mit "' + dataSearchAll + '"');
                    } else {
                        divResult.html(html);
                    }
                }
            })
            .fail(function (error) {
                console.log('Sorry an error ocurred!');
                def.reject();
            });
    }
}

function get_search(strShopId, strSearch) {
    var def = jQuery.Deferred();
    console.log(strSearch);

    $.ajax({
        url:    "https://www.der-felgenkatalog.de/wp-admin/admin-ajax.php",
        method: "POST",
        data: {
            action:   "atkp_live_search_render",
            limit:    2,
            shopid:   parseInt(strShopId),
            template: "grid_2_columns",
            filter:   "search=" + strSearch
        },
        dataType: "json",
        success: function(data){
            def.resolve(data)
        },
        error: function(){
            console.log('error');
            // def.reject();
        }
    });
    return def.promise();
}

$(document).ready(function () {
    search_amazon_article();
});